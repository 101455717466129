import { graphql, useStaticQuery } from 'gatsby'

export default function useDataPolicy() {
	const result = useStaticQuery(
		graphql`
			query DATA_POLICY {
				dataPolicy: allContentfulTenaRegistroV2(
					filter: { slug: { eq: "datos-de-contacto" } }
				) {
					nodes {
						node_locale
						politicaDeDatos {
							json
						}
						checksAceptoDePoliticas {
							idEtiqueta
							informacinPrincipal {
								json
							}
						}
					}
				}
			}
		`
	)

	const resultQuery = result.dataPolicy.nodes.filter(
		e => e.node_locale === process.env.LOCALE
	)[0]

	return {
		politicaDeDatos: resultQuery.politicaDeDatos,
		checksAceptoDePoliticas: resultQuery.checksAceptoDePoliticas,
	}
}
