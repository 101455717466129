import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import useDataPolicy from '../../../hooks/useDataPolicy'
import './data-policy.scss'

const DataPolicy = ({ className = '' }) => {
	const { politicaDeDatos } = useDataPolicy()
	return (
		<div className={`f-data-policy ${className}`}>
			{documentToReactComponents(politicaDeDatos.json)}
		</div>
	)
}

export default DataPolicy
